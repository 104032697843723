import React from 'react'
import clsx from 'clsx'
import NextLink from 'next/link'
import { isExternal } from '~/lib/externalLink'
import { Icon, Icons } from '../Icon/Icon'

export type ButtonStyle = 'Main' | 'Secondary' | 'Text' | 'TextSmall' | 'TextMin'
export type ButtonTheme = 'dark' | 'light'

interface CommonProps {
  variant?: ButtonStyle
  theme?: ButtonTheme
  href?: string
  fullWidth?: boolean
  icon?: Icons
  className?: string
  title?: string
  onClick?: React.MouseEventHandler<HTMLElement>
}

interface ButtonProps
  extends CommonProps,
    Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, keyof CommonProps> {}

interface AnchorProps
  extends CommonProps,
    Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, keyof CommonProps> {}

export type Props = ButtonProps & AnchorProps

const isEmail = (value: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/

  return emailRegex.test(value)
}

const isPhoneNumber = (value: string): boolean => {
  const phoneRegex = /^(\+|00)?[1-9]\d{1,14}$/

  return phoneRegex.test(value.replace(/\s|-|\(|\)/g, ''))
}

export const ButtonMain: React.FC<Props> = ({
  variant = 'Main',
  theme = 'dark',
  href,
  fullWidth = false,
  icon,
  children,
  className,
  ...props
}) => {
  const classes = clsx(
    {
      'leading-none no-underline outline-none focus:outline-none h-10 px-[30px] lg:px-10 lg:h-[46px] font-medium border inline-flex items-center justify-center rounded-full transition-colors duration-300 ease-out text-[12px] lg:text-[15px] gap-x-2.5':
        !variant.startsWith('Text'),
      'text-[15px]': variant === 'Text',
      'text-[12px]': variant === 'TextSmall',
      'text-[10px]': variant === 'TextMin',
      'w-full': fullWidth,
      'bg-sg-black border-sg-black text-sg-white hover:bg-transparent hover:text-sg-black hover:border-sg-black':
        variant === 'Main' && theme === 'dark' && !props.disabled,
      'bg-sg-white border-sg-white text-sg-black hover:bg-transparent hover:text-sg-white':
        variant === 'Main' && theme === 'light' && !props.disabled,
      'bg-transparent border-sg-black text-sg-black hover:bg-sg-black hover:text-sg-white':
        variant === 'Secondary' && theme === 'dark' && !props.disabled,
      'bg-transparent border-sg-white text-sg-white hover:bg-sg-white hover:text-sg-black':
        variant === 'Secondary' && theme === 'light' && !props.disabled,
      'pointer-events-none cursor-not-allowed': props.disabled,
      'bg-sg-light-grey border-sg-light-grey text-sg-grey':
        variant === 'Main' && theme === 'dark' && props.disabled,
      'bg-sg-white border-sg-light-grey text-sg-grey':
        variant === 'Main' && theme === 'light' && props.disabled,
      'bg-transparent border-sg-grey text-sg-grey': variant === 'Secondary' && props.disabled,
      'inline-flex underline underline-offset-4': variant.startsWith('Text'),
      'text-sg-black': variant.startsWith('Text') && theme === 'dark',
      'text-sg-white': variant.startsWith('Text') && theme === 'light',
    },
    className,
  )

  const content = (
    <>
      {icon && !variant.startsWith('Text') && (
        <Icon name={icon} className="inline-block h-4 w-4 lg:h-5 lg:w-5" />
      )}
      {children}
    </>
  )

  if (href) {
    if (isEmail(href)) {
      return (
        <a href={`mailto:${href}`} className={classes} {...(props as AnchorProps)}>
          {content}
        </a>
      )
    }

    if (isPhoneNumber(href)) {
      return (
        <a href={`tel:${href}`} className={classes} {...(props as AnchorProps)}>
          {content}
        </a>
      )
    }

    const external = isExternal(href)

    if (external) {
      return (
        <a
          href={href}
          className={classes}
          target="_blank"
          rel="noopener noreferrer"
          {...(props as AnchorProps)}
        >
          {content}
        </a>
      )
    }

    return (
      <NextLink href={href} className={classes} {...(props as AnchorProps)}>
        {content}
      </NextLink>
    )
  }

  return (
    <button className={classes} {...(props as ButtonProps)}>
      {content}
    </button>
  )
}
